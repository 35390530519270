import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/page-header';

import img4 from '../images/img4.jpg';
import aw1 from '../images/award1.jpg';
import aw2 from '../images/award2.jpg';
import aw3 from '../images/award3.jpg';
import aw4 from '../images/award4.jpg';
import aw5 from '../images/award5.jpg';
import aw6 from '../images/award6.jpg';
import aw7 from '../images/award7.jpg';
import aw8 from '../images/award8.jpg';
import JoinUs from '../components/join';

const AwardsPage = () => (
  <Layout>
    <SEO title="Reconocimientos" />
    <div className="page awards-page">
      <PageHeader bg={img4} />
      <div className="wrapper content">
        <h1>Reconocimientos</h1>
        <div className="row">
          <p>
            Por el <b>alto nivel educativo</b> con que cuenta la escuela, las secundarias de la zona escolar así lo reconocen y por lo mismo ofrecen a nuestros egresados el <b>pase automático y más beneficios</b>, así sabemos y estamos convencidos que nuestro deseo por alcanzar la excelencia constante se demuestra cuando nuestros EGRESADOS son reconocidos por su excelencia y a su vez nos reconocen como Institución por formar hombres y mujeres exitosos.
          </p>
        </div>
        <div className="awards-grid">
          <div className="award">
            <div className="diploma">
              <img src={aw1} alt=""/>
            </div>
            <h4>"Campaña de recolección de víveres para Guerrero (WFG)", 2013</h4>
          </div>
          <div className="award">
            <div className="diploma">
              <img src={aw2} alt=""/>
            </div>
            <h4>"Campaña de recolección de víveres para Guerrero (URGE)", 2013</h4>
          </div>
          <div className="award">
            <div className="diploma">
              <img src={aw3} alt=""/>
            </div>
            <h4>"Nutriente Amor", 2008</h4>
          </div>
          <div className="award">
            <div className="diploma">
              <img src={aw4} alt=""/>
            </div>
            <h4>"1er Concurso de Canto", 2007</h4>
          </div>
          <div className="award">
            <div className="diploma">
              <img src={aw5} alt=""/>
            </div>
            <h4>"Nivel Académico Secundaria", 2005</h4>
          </div>
          <div className="award">
            <div className="diploma">
              <img src={aw6} alt=""/>
            </div>
            <h4>"Olimpiada Conocimiento Infantil", 2004-2005</h4>
          </div>
          <div className="award">
            <div className="diploma">
              <img src={aw7} alt=""/>
            </div>
            <h4>"2do Lugar Concurso de Cantos", 1999</h4>
          </div>
          <div className="award">
            <div className="diploma">
              <img src={aw8} alt=""/>
            </div>
            <h4>"2do Lugar Tablas Gimnásticas", 1986</h4>
          </div>
        </div>
      </div>
      <JoinUs />
    </div>
  </Layout>
)

export default AwardsPage;